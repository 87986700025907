import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Alert,
  Divider,
  DialogTitle
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { axiosRequest } from '../../functions/axiosRequest';
import ResourceCard from './ResourceCard';
import CategoryDropDown from './CategoryDropDown';
import TypeDropDown from './TypeDropDown';
import { Close } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(250,250,250, 0.6)'
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(isMobile ? 2 : 5),
      background: theme.palette.grey[100],
      '& #dialog_content': {
        position: 'relative',
        padding: theme.spacing(isMobile ? 1 : 3),
        border: isMobile ? 'none' : `3px solid ${theme.palette.common.white}`,
        '& .MuiIconButton-root': {
          position: 'absolute',
          top: -34,
          right: -34,
          color: theme.palette.resources.main,
          '& svg': {
            fontSize: '2.5rem',
            '& .fa-secondary': {
              color: theme.palette.resources.main,
              opacity: 1
            },
            '& .fa-primary': {
              color: 'white'
            }
          }
        }
      }
    }
  },
  title: {
    background: theme.palette.resources.main,
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    fontWeight: 400
  },

  subtitle: {
    fontWeight: 400
  }
}));

const ResourceDialog = ({ open, handleClose }) => {
  // ****************************** STYLES ******************************
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [resources, setResources] = useState(null);
  const [visibleResources, setVisibleResources] = useState(null);

  //console.log(selectedCategory)

  const allResources = useMutation((id) => {
    return axiosRequest({
      method: 'get',
      endpoint: 'resources',
      filters: `publicationState=live&filters[category][id][$eq]=${id}`
    })
  }, {
    onSuccess: (data) => {
      console.log(data)
      setResources(data.data)
    }
  })

  useEffect(() => {
    if (selectedCategory?.id) {
      allResources.mutate(selectedCategory.id)
    }
  }, [selectedCategory])


  useEffect(() => {
    if (resources && selectedType) {
      // const types = [{
      //   'name': 'All Resources', id: 0
      // }, {
      //   'name': 'PDF', id: 1
      // }, {
      //   'name': 'Documents', id: 2
      // }, {
      //   'name': 'Images', id: 3
      // }, {
      //   'name': 'Videos', id: 4
      // }
      // ]
      console.log(resources, selectedType)
      let visible = resources
      if (selectedType.id === 1) {
        visible = resources.filter((resource) => resource.attributes.type[0].__component === 'resource.file')
      } else if (selectedType.id === 2) {
        visible = resources.filter((resource) => resource.attributes.type[0].__component === 'resource.content')
      } else if (selectedType.id === 3) {
        visible = resources.filter((resource) => resource.attributes.type[0].__component === 'resource.image')
      } else if (selectedType.id === 4) {
        visible = resources.filter((resource) => resource.attributes.type[0].__component === 'resource.video')
      }
      setVisibleResources(visible)

    }
  }, [resources, selectedType])

  console.log(visibleResources)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="resources_dialog"
      className={classes.dialog}
      fullWidth
      maxWidth="xl"
    >
      {isMobile &&
        <DialogTitle>
          The Repository
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.resources.main,
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} size='lg' />
            </IconButton>
          ) : null}
        </DialogTitle>
      }
      <DialogContent>
        <Grid
          container
          spacing={1}
          alignItems="center"
          direction="column"
          id="dialog_content"
        >
          {!isMobile ?
            <>
              <IconButton
                aria-label="close_dialog"
                onClick={handleClose}
                className={classes.closeIcon}
                col="resources"
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </IconButton>
              <Grid container item >
                <Grid item xs={12} textAlign="center" >
                  <Typography variant={isMobile ? 'p' : 'h6'}
                    sx={{
                      background: theme => theme.palette.resources.main,
                      padding: theme => theme.spacing(2),
                      color: theme => theme.palette.common.white,
                      margin: '0  auto 1rem auto',
                      fontWeight: 400,
                      width: 'fit-content'
                    }}
                  >
                    The Repository
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" >
                  <Typography variant={isMobile ? 'p' : 'h6'} sx={{ marginBottom: '1em' }}>
                    Scroll through to view media and documentation
                  </Typography>
                </Grid>
              </Grid>
            </>
            : null}
          <Grid container spacing={2} justifyContent='center' item>
            <Grid item xs={isMobile ? 6 : 'auto'}>
              <CategoryDropDown
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            </Grid>
            <Grid item xs={isMobile ? 6 : 'auto'}>
              <TypeDropDown
                selectedType={selectedType}
                setSelectedType={setSelectedType}
              />
            </Grid>
          </Grid>
          {allResources.isLoading ? (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body1" color="textPrimary">
                  Getting Catgories
                </Typography>
              </Grid>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : visibleResources ? (
            Object.values(visibleResources).length ? (
              <Grid container item spacing={2} sx={{
                marginTop: '0.5em',
                maxHeight: isMobile ? 'auto' : '600px',
                overflowX: 'auto',
                padding: '1em'
              }}>
                {visibleResources.map(resource => {
                  return (
                    resource.attributes.in_resources && (
                      <ResourceCard resource={resource.attributes} key={resource.id} />
                    )
                  );
                })}
              </Grid>
            ) : (
              <Grid item sx={{ marginTop: '1em' }}>
                <Alert severity="info">No Resources in this Category or the selected file Type</Alert>
              </Grid>
            )
          ) : null}

          {/* MEDIA CONTENT */}
          {/* {isLoading ? (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body1" color="textPrimary">
                  Getting resources
                </Typography>
              </Grid>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : isError ? (
            <Grid item>
              <Typography variant="body1" color="error">
                {error.message}
              </Typography>
            </Grid>
          ) : resources?.data ? (
            resources.data.length ? (
              <Grid container spacing={2}>
                {resources.data.map(resource => {
                  return (
                    resource.attributes.in_resources && (
                      <ResourceCard resource={resource.attributes} key={resource.id} />
                    )
                  );
                })}
              </Grid>
            ) : (
              'No resources'
            )
          ) : null} */}
        </Grid>
      </DialogContent>
    </Dialog >
  );
};

export default ResourceDialog;
