import React, { useRef, useEffect, useState } from "react";

const VideoCanvas = ({ background_url, onVideoEnd , video_ref}) => {

  const [hasPlayed, setHasPlayed] = useState(false);
  
console.log(video_ref)   

  useEffect(() => {

    
    console.log(background_url)

    if (video_ref?.current && background_url) {
      const video = document.createElement("video");
      video.src = background_url;
      video.controls = true;

      const canvas = video_ref.current;
      const context = canvas.getContext("2d");
      const aspectRatio = 1920 / 1080;
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const windowAspectRatio = windowWidth / windowHeight;

      let videoWidth, videoHeight;

      if (windowAspectRatio < aspectRatio) {
        videoWidth = windowWidth;
        videoHeight = windowWidth / aspectRatio;
        canvas.width = windowWidth;
        canvas.height = windowWidth / aspectRatio;
      } else {
        videoWidth = windowHeight * aspectRatio;
        videoHeight = windowHeight;
        canvas.width = windowHeight * aspectRatio;
        canvas.height = windowHeight;
      }

      if (!hasPlayed) {
        video.addEventListener("loadedmetadata", function() {
          video.play();
          draw();
        });
        setHasPlayed(true);
      }

      function draw() {
        context.drawImage(video, 0, 0, videoWidth, videoHeight);
        requestAnimationFrame(draw);
      }

      video.addEventListener("ended", function() {
        if (onVideoEnd) {
          onVideoEnd();
        }
      });

      window.addEventListener("resize", function() {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const windowAspectRatio = windowWidth / windowHeight;

        let videoWidth, videoHeight;

        if (windowAspectRatio < aspectRatio) {
          videoWidth = windowWidth;
          videoHeight = windowWidth / aspectRatio;
          canvas.width = windowWidth;
          canvas.height = windowWidth / aspectRatio;
        } else {
          videoWidth = windowHeight * aspectRatio;
          videoHeight = windowHeight;
          canvas.width = windowHeight * aspectRatio;
          canvas.height = windowHeight;
        }
      });

      return () => {
        window.removeEventListener("resize", function() {});
      };
    }
  }, [video_ref, background_url, onVideoEnd, hasPlayed]);

  return (
    <canvas ref={video_ref} id="video-canvas"></canvas>
  );
};

export default VideoCanvas;
