
import React, { useContext } from 'react';
import { AreaContext } from '../store/AreaContext';
import { PLAY, SAVE_DESTINATION } from '../store/AreaReducers';
import { useHistory } from 'react-router-dom';
import { slice } from 'lodash';
import { Card, CardContent, CardHeader, Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { isMobile } from 'react-device-detect';

const BackButton = ({ overrideText, parent_area, area_name, area_desc }) => {

  const history = useHistory();
  const xlDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));

  const { dispatchTransition, currentArea, dispatchDestination } = useContext(AreaContext);
  //console.log(currentArea)

  const handleClick = () => {

    const pathArray = history.location.pathname.split('/');
    const newPath = slice(pathArray, 1, pathArray.length - 1).join('/');
    if (currentArea.slug === '/') return; //if homepage 

    dispatchDestination({
      type: SAVE_DESTINATION,
      payload: {
        to: `/${newPath}`,
        transition_url: currentArea?.transition_out ? currentArea.transition_out.attributes.url : '',
        //split_screen: currentArea.parent_area.split_screen || null
      }
    });
    if (
      !currentArea.background ||
      (currentArea.background && currentArea.background.data.attributes.mime.includes('image'))
    ) {
      dispatchTransition({
        type: PLAY,
        payload: {
          url: currentArea?.transition_out ? currentArea.transition_out.attributes.url : '',
          to: `/${newPath}`,
        }
      });
    }
  };

  //console.log(parent_area)
  //console.log(area_name)

  return (

    <>
      {parent_area.data ?
        <Card sx={{
          marginBottom: '1em',
          borderRadius: 0
        }} >
          <CardHeader
            sx={{
              textTransform: 'uppercase',
              background: theme => theme.palette.primary.main,
              color: '#fff',
              fontWeight: 300,
              cursor: 'pointer',
              '& span ': {
                fontSize: isMobile ? '.75em' : '1em'
              },
              padding: theme => theme.spacing(isMobile ? 1 : 2)
            }}
            avatar={<FontAwesomeIcon icon={faChevronLeft} />}
            title={`back to ${overrideText ? overrideText : parent_area.data.attributes.name}`}
            onClick={handleClick}
          />
          {(area_name || area_desc) && (
            <CardContent
              sx={{
                textAlign: 'center',
                padding: theme => theme.spacing(isMobile ? 1 : 2),
                paddingBottom: theme => `${theme.spacing(isMobile ? 1 : 2)}!important`,
                maxWidth: xlDown ? '200px' : 'auto',
                margin: '0 auto'
              }}
            >
              {area_name &&
                <Typography
                  sx={{
                    textTransform: 'uppercase',
                    lineHeight: 1.4
                  }}
                  component="p"
                  variant={isMobile ? "caption" : xlDown ? "body1" : "h6"} >
                  {area_name}</Typography>}
              {area_desc && <Typography component="p" variant={isMobile ? "caption" : "body1"}>{area_desc}</Typography>}
            </CardContent>
          )}
        </Card>

        :

        <Card sx={{
          marginBottom: '1em',
          borderRadius: 0
        }} >
          <CardHeader
            sx={{
              textTransform: 'uppercase',
              background: theme => theme.palette.primary.main,
              color: '#fff',
              textAlign: 'center',
              cursor: 'pointer'
            }}
            title={<Typography sx={{ fontWeight: 300 }} componenet="p" variant={xlDown ? "body1" : "h6"}>{area_name}</Typography>}
          //onClick={handleClick}
          />
          {area_desc && (
            <CardContent
              sx={{
                textAlign: 'center',
              }}>
              <Typography variant="body1">{area_desc}</Typography>
            </CardContent>
          )}
        </Card>
      }
    </>
  );
};

export default BackButton;
