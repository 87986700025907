import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Box, Grid, Step, StepConnector, StepLabel, Stepper, Typography, useMediaQuery } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { AreaContext } from '../store/AreaContext';
import { PLAY, SAVE_DESTINATION } from '../store/AreaReducers';
import { isMobile } from 'react-device-detect';
import { useQuery } from 'react-query';
import { axiosRequest } from '../functions/axiosRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTree } from '@fortawesome/pro-light-svg-icons';
//import handleImageSnapshot from '../functions/handleImageSnapshot';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: '100%'
  },
  container: {
    width: '100%',
    height: '0px',
    position: 'relative',
    paddingTop: '56.25%',
  },
  sub_area: {
    height: '100%',
    width: '100vw',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  // video: {
  //   width: '100%',
  //   height: '100%',
  //   position: 'absolute',
  //   top: '0',
  //   left: '0'
  // }
}));


const Timeline = forwardRef(({ area }, ref) => {
  const classes = useStyles();
  const { dispatchTransition, dispatchDestination } = useContext(AreaContext);
  const xlDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));

  //console.log(area.attributes.area_type[0].timeline.data.id)

  const { status, data, error, isFetching, isLoading } = useQuery(['timeline', area.attributes.area_type[0].timeline.data.id],
    () => axiosRequest({
      method: 'get',
      endpoint: `timelines/${area.attributes.area_type[0].timeline.data.id}`,
      params: { _sort: 'id:ASC' }
    }), {
    retry: 1,
    enabled: !!area.attributes.area_type[0].timeline.data.id
  })

  const [timelinePoints, setTimelinePoints] = useState();
  const [currentPoint, setCurrentPoint] = useState();

  //create points including active, next and previous
  useEffect(() => {
    if (data && area) {
      //console.log(area.id)
      //console.log(data.timeline_points)

      let copy = structuredClone(data.data.attributes.timeline_points)
      //console.log(copy)
      //console.log(area)
      //find current
      let index = copy.findIndex((point) => point.page.data.id === area.id)
      //console.log(index)
      //matching page found
      if (index > -1) {
        copy[index].active = true
      }
      //add previous
      if (index > 0) {
        copy[index - 1].previous = true
      }
      //and next
      if (index + 1 !== copy.length) {
        copy[index + 1].next = true
      }
      //console.log(copy)
      setTimelinePoints(copy)
      setCurrentPoint(copy[index])
    }
  }, [data, area])



  const handleTimelineClick = (point) => {
    // // Save the destination area
    // console.log(point)
    // console.log(currentPoint)
    let transition_url

    if (point.previous) {
      //console.log('back')
      transition_url = currentPoint.transition_prev.data?.attributes?.url

    } else if (point.next) {
      //console.log('next')
      transition_url = currentPoint.transition_next.data?.attributes?.url
    } else {
      //console.log('here')
      // not prevoius or next, so do a fade to page
      transition_url = null
    }
    //console.log(transition_url)

    dispatchDestination({
      type: SAVE_DESTINATION,
      payload: {
        to: point.page.data.attributes.slug,
        transition_url: transition_url
      }
    });
    dispatchTransition({
      type: PLAY,
      payload: {
        url: transition_url,
        to: point.page.data.attributes.slug,
      }
    });
    setTimelinePoints()
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    top:isMobile ? 35 :  50,
    height: 4,
    border: 0,
    backgroundColor: '#FFF',
    borderRadius: 0,
    width: isMobile ? '28px' : xlDown ? '45px' : '80px',
    left: isMobile ? '-13px' : xlDown? '-22px': '-43px',
    '& span': {
      display: 'none'
    }
  }));

  const CustomIcon = ({ point }) => {
    //console.log(point)
    return (
      <Box sx={{
        borderColor: '#FFF',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderRadius: '30px',
        width: isMobile ? 75 : xlDown ? 85 : 100,
        height: isMobile ? 75 : xlDown ? 85 : 100,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: !point.active ? 'pointer' : 'default',
        '& > .MuiBox-root': {
          backgroundColor: point.active ? '#f7cd36' : '#008c9d',
          transition: '0.1s ease background-color',
        },

      }}
        onClick={() => {
          if (!point.active) {
            handleTimelineClick(point)
          }
        }}
      >
        <Box sx={{
          borderRadius: '50%',
          width: isMobile ? 40 : xlDown ? 50 : 75,
          height: isMobile ? 40 : xlDown ? 50 : 75,
          display: 'flex',

          justifyContent: 'center',
          alignItems: 'center',
          '& svg': {
            color: '#FFF',
            fill: '#FFF'
          }
        }} >
          {point?.icon?.data?.attributes?.url ?
            <img
              style={{
                width: isMobile ? 35 : xlDown ? 45 : 70,
                height: isMobile ? 35 : xlDown ? 45 : 70,
              }}
              alt="certis"
              src={`${process.env.REACT_APP_API}${point.icon.data.attributes.url}`}
            //src={point.icon.data.attributes.url}
            />
            :
            <FontAwesomeIcon icon={faTree} size='2x' />
          }
        </Box>
      </Box>
    );
  }

  return (
    <Grid container
      className={classes.root}
      sx={{
        top: '24%'
      }}
      justifyContent="center">
      <Stepper alternativeLabel connector={<ColorlibConnector />}>
        {timelinePoints ?
          timelinePoints.map((point, i) => {
            return (
              <Step key={i} sx={{
                width: isMobile ? 100 : xlDown ? 150 : 200,
                cursor: 'pointer',
                '&:hover p': {
                  textShadow: '1px 1px 3px #ffffff9f'
                }
              }}>
                <StepLabel
                  sx={{
                    '&:hover .MuiStepLabel-iconContainer > .MuiBox-root > .MuiBox-root': {
                      backgroundColor: point.active ? '#f7cd36' : '#015560',
                    }
                  }}
                  StepIconComponent={() => <CustomIcon point={point} />}
                >
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      color: '#00303b',
                      cursor: !point.active ? 'pointer' : 'default',
                      fontWeight: 700,
                      textShadow: '1px 1px #ffffff4f',
                      transition: '0.3s ease text-shadow',
                      //fontSize: '1em'
                    }}
                    component="p"
                    variant={isMobile ? "caption" : xlDown ? "body1" : "h6"}
                  >
                    {point.title}
                  </Typography>
                </StepLabel>
              </Step>
            )
          })
          : null}
      </Stepper>
    </Grid>
  );
}
);

export default Timeline;
