import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid, Link, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import ReactMarkdown from 'react-markdown'

import {
  card_image,
  card_text,
  icon,
  info_card
} from '../styles/info_variants';

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    position: 'relative',
    background: '#ffffff',
    padding: theme.spacing(2),
    maxWidth: theme.spacing(90),
    zIndex: "inherit"
  },
  card_border: {
    borderRadius: 0,
    position: 'absolute',
    background: '#ffffff',
    padding: theme.spacing(2),
    maxWidth: theme.spacing(90)
  },
  card_right_size: ({ info_open }) => {
    return {
      paddingLeft: theme.spacing(2)
    };
  },
  card_image: {
    alignSelf: 'center',
  },
  circle: ({ info_open, colour }) => ({
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: colour ? colour : theme.palette.primary.main,
    position: 'absolute',
    right: '-16px',
    zIndex: "inherit",
    //zIndex: theme.zIndex.tooltip,
    transition: 'bottom ease-out 0.3s',
    //bottom: info_open ? 'calc(100% - 16px)' : '-16px',
    bottom: '-16px',
    width: '35px',
      height: '35px',
    [theme.breakpoints.down('xl')]: {
      width: '35px',
      height: '35px'
    },
    [theme.breakpoints.down('lg')]: { width: '30px', height: '30px' },
    [theme.breakpoints.down('sm')]: {
      width: '25px',
      height: '25px'
    },
    '&:hover': {
      transform: 'scale(1.3)',
      cursor: 'pointer'
    }
  }),
  circle_icon: {
    color: theme.palette.common.white,
    fontSize: '15px'

  }
}));

const HotspotInfoCard = ({
  hotspot_info,
  position_x,
  info_open,
  setInfo_open,
  handleIcon
}) => {

  const colour = hotspot_info?.colour
  const classes = useStyles({ info_open, colour });
  // const handleIcon = () => {setInfo_open(prevState => !prevState);

  return (
    <>
      <Grid
        className={classes.card}
        container
        component={motion.div}
        variants={info_card}
        initial="hidden"
        animate={info_open ? 'visible' : 'hidden'}
      >
        {/* ***************** CARD - ICON ***************** */}
        <motion.div
          className={classes.circle}
          variants={icon}
          whileHover="hover"
          initial="hidden"
          animate="visible"
          onClick={handleIcon}
          color={hotspot_info.colour}
        >
          {hotspot_info.tooltip ?
            <Tooltip title={hotspot_info.tooltip ? hotspot_info.tooltip : null} arrow>
              <Box>
                <FontAwesomeIcon
                  icon={info_open ? faMinus : faPlus}
                  className={`${classes.circle_icon}`}
                />
              </Box>
            </Tooltip>
            :
            <FontAwesomeIcon
              icon={info_open ? faMinus : faPlus}
              className={`${classes.circle_icon}`}
            />
          }
        </motion.div>
        <Grid
          item
          container
          //direction={position_x > 50 ? 'row-reverse' : 'row'}
          component={motion.div}
          alignItems="stretch"
          style={{ height: '100%', overflow: 'hidden', position: 'relative', zIndex: 2 }}
        >


          {/* ***************** CARD - IMAGE ******************/}

          <Grid item xs={4} sx={{ position: 'relative' }}>
            <Grid
              component={motion.div}
              item
              sx={{ width: '100%', height: '100%' }}
              className={classes.card_image}
              id="card_left"
              variants={card_image}
              exit={{ display: 'none', transition: { delay: 2 } }}
            >
              {hotspot_info?.info_media?.data ? 
              <img
                style={{ height: '100%', width: '100%', objectFit: 'cover', position: 'absolute' }}
                //src={hotspot_info.info_media.data.attributes.url}
                src={`${process.env.REACT_APP_API}${hotspot_info.info_media.data.attributes.url}`}
                alt={hotspot_info.info_media.data.attributes.alternativeText}
              />
              : null }
            </Grid>
          </Grid>
          <AnimatePresence>

            {info_open && (

              <Grid
                item
                xs={8}
                direction="column"
                container
                className={classes.card_right_size}
                component={motion.div}
                id="card_right"
                // remove the right side otherwise will prevent hover on anything below because still present although non visible
                exit={{ display: 'none', transition: { delay: 2 } }}
              >
                {/* ***************** RIGHT - TITLE ***************** */}
                <Grid item sx={{ paddingTop: '3em' }}>
                  <Typography
                    variant="h6"
                    color="primary"
                    component={motion.p}
                    variants={card_text}
                    sx={{
                      maxWidth: '60%',
                      lineHeight: 1.2,
                      marginBottom: '1em'
                    }}
                  >
                    {hotspot_info.title}
                  </Typography>
                </Grid>

                <Grid item style={{ flex: 1 }}>
                   <Typography 
                   component={motion.p} 
                   variants={card_text}
                   > 
                  <ReactMarkdown>{hotspot_info.description}</ReactMarkdown>
                </Typography> 
                </Grid>

                {/* ***************** RIGHT - LINK ******************/}
                {hotspot_info.external_link && (
                  <Grid item>
                    <Link
                      component={motion.a}
                      color="textPrimary"
                      className={classes.card_description}
                      variants={card_text}
                      href={hotspot_info.external_link}
                    >
                      {`${hotspot_info.external_link_text} >`}
                    </Link>
                  </Grid>
                )}
              </Grid>

            )}
          </AnimatePresence>
        </Grid>
      </Grid>
    </>
  );
};

export default HotspotInfoCard;
