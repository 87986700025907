import React, { useEffect, useState } from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { axiosRequest } from '../../functions/axiosRequest';
import { isMobile } from 'react-device-detect';

const CategoryCard = ({ selectedCategory, setSelectedCategory }) => {

  const [categories, setCategories] = useState();

  const { data } = useQuery('categories',
    () => axiosRequest({
      method: 'get',
      endpoint: 'categories',
      params: {}
    }), {
    retry: 1
  })

  useEffect(() => {
    if (data) {
      setCategories(data.data)
      setSelectedCategory(data.data[0])
    }
  }, [data])

  //console.log(categories)
  //console.log(selectedCategory)

  return (

    <Autocomplete
      sx={{
        width: isMobile ? '100%' : '300px',
        '& .MuiAutocomplete-endAdornment': {
          right: '43px!important',
          top: '39px!important'
        }
      }}
      disableClearable
      //multiple={true}
      id="selected-brands"
      value={selectedCategory}
      options={categories || []}
      getOptionLabel={option => option.attributes.title}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id
      }
      onChange={(event, newValue) => setSelectedCategory(newValue)}
      renderInput={params => (
        <TextField {...params} variant="outlined" label="Select Crop" />
      )}
      loadingText="Updating Categories...."

    />
  );
};

export default CategoryCard;
