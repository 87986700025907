import React, { useEffect, useState } from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';


const types = [{
  'name': 'All Resources', id: 0
}, {
  'name': 'PDF', id: 1
}, {
  'name': 'Documents', id: 2
}, {
  'name': 'Images', id: 3
}, {
  'name': 'Videos', id: 4
}
]

const TypeDropDown = ({ selectedType, setSelectedType }) => {

  useEffect(() => {
    if (types) {
      setSelectedType(types[0])
    }
  }, [types])


  return (

    <Autocomplete
      sx={{
        width: isMobile ? '100%' : '300px',
        '& .MuiAutocomplete-endAdornment': {
          right: '43px!important',
          top: '39px!important'
        }
      }}
      disableClearable
      //multiple={true}
      id="selected-brands"
      value={selectedType}
      options={types || []}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id
      }
      onChange={(event, newValue) => setSelectedType(newValue)}
      renderInput={params => (
        <TextField {...params} variant="outlined" label="Select Type" />
      )}
    />
  );
};

export default TypeDropDown;
