import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router';
import Area from '../components/Area';
import addRoutesToAreas from '../functions/addRoutesToAreas';
import { axiosRequest } from '../functions/axiosRequest';
import handleVideoSnapshot from '../functions/handleVideoSnapshot';
import useWindowSize from '../functions/useWindowSize';
import { AreaContext } from '../store/AreaContext';
import { SAVE_ALL_AREAS } from '../store/AreaReducers';
import { Box, Fade } from '@mui/material';
import MainCanvas from '../components/canvas/MainCanvas';

const isBrowser = typeof window !== 'undefined'

const App = () => {

  const video_ref = useRef(null);
  const canvas_ref = useRef(null);
  const { transition, dispatchAllAreas } = useContext(AreaContext);
  const [appHeight, setAppHeight] = useState(0);
  const [fixed, setFixed] = useState(false);
  const [portrait, setPortrait] = useState(false);
  const [allAreas, setAllArea] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [isVisible, setIsVisible] = useState();
  //const [finish, setFinish] = useState();
  //console.log(transition.play)

  useEffect(() => {
    if (transition.play) {
      //console.log(transition.play)
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }, [transition])


  const { height: windowHeight, width: windowWidth } = useWindowSize();


  const { status, data, error, isFetching, isLoading } = useQuery('allAreas',
    () => axiosRequest({
      method: 'get',
      endpoint: 'areas',
      params: {
        _sort: 'id:ASC',
        //populate: '*' //update to bring back only what is needed
      }
    }), {
    retry: 1
  })

  //check browser size and rotation

  useEffect(() => {
    setFixed(windowHeight <= appHeight);
    setPortrait(windowHeight > windowWidth);
  }, [appHeight, windowWidth, windowHeight]);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('orientationchange', e =>
        setPortrait(e.target.screen.orientation.type.includes('portrait'))
      );
    }
    return () =>
      window.addEventListener('orientationchange', e =>
        setPortrait(e.target.screen.orientation.type.includes('portrait'))
      );
  }, []);

  //save all areas, add breadcrumb routes
  useEffect(() => {
    if (data) {
      //console.log(data)
      let allAreas = addRoutesToAreas(data)
      //console.log(allAreas)
      //add routes to all hotspots
      allAreas.map((area) => {
        if (area.attributes.hotspots.data.length) {
          area.attributes.hotspots.data = area.attributes.hotspots.data.map(hotspot => {
            //console.log(hotspot)
            return {
              ...hotspot,
              route: area.route !== '/' ? `${area.route}/${hotspot.attributes.slug}` : `/${hotspot.attributes.slug}` // check for hotspot on main map top level   
            };
          });
        }
      })
      setAllArea(structuredClone(allAreas))
      dispatchAllAreas({
        type: SAVE_ALL_AREAS,
        payload: structuredClone(allAreas)
      })
    }
  }, [data]);

  //console.log(allAreas)

  if (isLoading) return <p style={{ background: 'black' }}>Loading...</p>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;

  return (
    <>
      <Box
        ref={ref => ref && setAppHeight(ref.clientHeight)}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          display: 'grid',
          alignItems: 'center',
          minWidth: '100vw',
          minHeight: '100vh',
          justifyContent: 'center',
          alignContent: 'center',
          zIndex: 2
        }}
      >
        <Switch>
          {allAreas &&
            allAreas.map(area => {
              //console.log(area)
              return (
                <Route key={area.id} path={`${area.route}`} exact>
                  <Area
                    portrait={portrait}
                    firstLoad={firstLoad}
                    setFirstLoad={setFirstLoad}
                    //area_id={area.id}
                    area={area}
                    handleVideoSnapshot={() => handleVideoSnapshot({ canvas_ref, video_ref })}
                    canvas={canvas_ref}
                    video_ref={video_ref}
                    fixed={fixed}
                  />
                </Route>
              );
            })}
        </Switch>
      </Box>
      <Box sx={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        overflow: 'hidden',
        display: 'grid',
        alignItems: 'center',
        minWidth: '100vw',
        minHeight: '100vh',
        justifyContent: 'center',
        alignContent: 'center',
        zIndex: 1
      }}>
        <Box
          sx={{
            position: 'relative',
            display: 'grid',
            width: '100%',
            height:'100%',
            alignItems: 'center'
          }}
        >
          <MainCanvas canvas_ref={canvas_ref} />
        </Box>
      </Box>
    </>
  );
};
export default App;
