import { Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import handleImageSnapshot from '../functions/handleImageSnapshot';
import { AreaContext } from '../store/AreaContext';
import { FADE, PLAY, SAVE_DESTINATION } from '../store/AreaReducers';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    position: 'relative',
    cursor: 'pointer'
  },
  dot: {
    width: '0.8vw',
    height: '0.8vw',
    minWidth: '10px',
    minHeight: '10px',
    maxWidth: '16px',
    maxHeight: '16px',
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    '& .vertical_line': {
      width: 4,
      maxHeight: 80,
      background: 'white',
      position: 'absolute',
      bottom: 0
    }
  },
  circle: {
    width: '2vw',
    height: '2vw',
    minWidth: '30px',
    minHeight: '30px',
    maxWidth: '40px',
    maxHeight: '40px',
    [theme.breakpoints.up('lg')]: {
      border: `4px solid ${theme.palette.primary.main}`
    },
    [theme.breakpoints.down('lg')]: {
      border: `3px solid ${theme.palette.primary.main}`
    },
    [theme.breakpoints.down('md')]: {
      border: `2px solid ${theme.palette.primary.main}`
    },
    position: 'absolute',
    boxShadow: `inset 0 0 10px -1px white, 0 0 8px 3px white`
  },
  nav_name: {
    // position: 'absolute',
    top: -30,
    left: -2,
    zIndex: theme.zIndex.appBar,
    width: 'max-content',
    // color: theme.palette.common.white,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `0 ${theme.spacing(1)}px`
  },
  svg: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  hotspot_icon: {
    position: 'absolute',
    width: '100%',
    '& svg': {
      position: 'absolute',
      width: '100%'
    }
  },
  border: {
    width: '100%',
    height: '90%'
  },
  fake_path_top: {
    position: 'absolute',
    left: -2,
    bottom: 71,
    //height: '80px',
    maxHeight: `calc(100px + 32px)`,
    [theme.breakpoints.down('lg')]: {
      maxHeight: `calc(100px + 25px)`
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: `calc(100px + 20px)`
    },
    '& .horizontal_line': {
      height: 4,
      background: 'white'
    }
  },
  fake_path_bottom: {
    position: 'absolute',
    left: -2,
    top: 71,
    //height: '100px',
    maxHeight: `calc(100px + 32px)`,
    [theme.breakpoints.down('lg')]: {
      maxHeight: `calc(100px + 25px)`
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: `calc(100px + 20px)`
    },
    '& .horizontal_line': {
      height: 4,
      background: 'white'
    }
  }
}));

const HotspotNavigator = ({ hotspot, video_ref, area, canvas }) => {
  const classes = useStyles();
  const [open_modal, setOpen_modal] = useState(false);
  const { dispatchDestination, dispatchTransition } = useContext(AreaContext);
  const xlDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const [background_url, setBackground_url] = useState(area?.attributes?.background?.data?.attributes?.url);

  //console.log(background_url)

  const handleClick = () => {
    //console.log('here')
    if (isMobile) setOpen_modal(prevState => !prevState);

    //console.log(area)
    //set canvas to the curren tbackground image
    handleImageSnapshot(canvas, background_url);
    const transition_in_url = hotspot?.attributes.type[0]?.transition_in?.data.attributes.url || null;
    // // Save the destination area

    setTimeout(() => {
      dispatchDestination({
        type: SAVE_DESTINATION,
        payload: {
          to: hotspot.route,
          transition_url: transition_in_url
        }
      });

      if (transition_in_url) {
        dispatchTransition({
          type: PLAY,
          payload: {
            url: transition_in_url,
            to: hotspot.route
          }
        });
      } else {
        //no video, just fade
        dispatchTransition({
          type: FADE,
          payload: hotspot.route
        });
      }
    }, [200])
    // added time out so there is no image flash
  };

  const openModal = () => {
    setOpen_modal(true);
  };
  const closeModal = () => {
    setOpen_modal(false);
  };

  // const draw = {
  //   hidden: { pathLength: 0, opacity: 0 },
  //   visible: (i) => {

  //     return {
  //       pathLength: 1,
  //       opacity: 1,
  //       transition: {
  //         pathLength: { type: "spring", duration: 2, bounce: 0 },
  //         opacity: { duration: 0.01 }
  //       }
  //     };
  //   }
  // };

  return (
    <div
      className={`${classes.root} ${classes.hover}`}
      onClick={handleClick}
      onMouseEnter={openModal}
      onMouseLeave={closeModal}
    >
      <span className={`${classes.root} ${classes.circle} circle`}></span>


      <span className={` ${classes.root} ${classes.dot} dot`}>
        {!isMobile ?
          <AnimatePresence>
            {open_modal && (
              <motion.span
                className="vertical_line"
                initial={{ height: 0, top: hotspot.attributes.position_y < 40 ? '0' : 'auto', transform: `rotate( ${hotspot.attributes.position_y < 40 ? '180deg' : 'none'})` }}
                animate={{ height: '80px' }}
                exit={{ height: 0, transition: { delay: 0.6 } }}
                transition={{ duration: 0.3 }}
              />
            )}

          </AnimatePresence>
          : null}
      </span>

      {!isMobile ?
        <AnimatePresence>
          {open_modal && (
            <motion.div className={hotspot.attributes.position_y > 40 ? classes.fake_path_top : classes.fake_path_bottom}>
              {hotspot.attributes.position_y < 40 &&
                <motion.div
                  className="horizontal_line"
                  initial={{ width: 0 }}
                  animate={{ width: '100%', transition: { delay: 0.3 } }}
                  exit={{ width: 0, transition: { delay: 0.3 } }}
                  transition={{ duration: 0.3 }}
                ></motion.div>
              }
              <Typography
                component={motion.h5}
                variant="h5"
                className={classes.nav_name}
                initial={{ scale: 0 }}
                animate={{ scale: 1, transition: { delay: 0.6 } }}
                exit={{ scale: 0 }}
                transition={{ duration: 0.3 }}
                sx={{ padding: '10px' }}
              >
                {hotspot.attributes.type ? hotspot.attributes.type[0].title : null}
              </Typography>
              {hotspot.attributes.position_y > 40 &&
                <motion.div
                  className="horizontal_line"
                  initial={{ width: 0 }}
                  animate={{ width: '100%', transition: { delay: 0.3 } }}
                  exit={{ width: 0, transition: { delay: 0.3 } }}
                  transition={{ duration: 0.3 }}
                ></motion.div>
              }
            </motion.div>
          )}
        </AnimatePresence>
        :
        <Typography
          variant="caption"
          sx={{
            top: '20px',
            position: 'absolute',
            whiteSpace: 'nowrap',
            color: "#fff",
            fontWeight: 700,
            textAlign: 'center',
            backgroundColor: theme => theme.palette.primary.main,
            padding: '5px',
            borderRadius: '5px'


          }}
        >
          {hotspot.attributes.type ? hotspot.attributes.type[0].title : null}
        </Typography>
      }
    </div>
  );
};

export default HotspotNavigator;
