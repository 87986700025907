import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import { BrowserRouter } from 'react-router-dom';
import theme from './styles/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AreaProvider } from './store/AreaContext';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, GlobalStyles, ThemeProvider } from "@mui/material";
import { FarmProvider } from './store/FarmContext';
//import { createBrowserHistory } from "history";

//const history = createBrowserHistory();
//test
// Create a client - set defaults
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false
    },
  },
})

ReactDOM.render(
  <AreaProvider>
    <FarmProvider>
      <StyledEngineProvider injectFirst>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <GlobalStyles
              styles={{
                body: {
                  overflow: 'auto',
                  backgroundColor: 'transparent'
                },
                html: {
                  background: 'black'
                }
              }}
            />
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </QueryClientProvider>
      </StyledEngineProvider>
    </FarmProvider>
  </AreaProvider>,
  document.getElementById('root')
);
